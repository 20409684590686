/* Import Poppins typeface */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
.gradient-background {
    background: linear-gradient(45deg, #0b7074 0%, #4cf593 100%);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    font: 400 16px 'Poppins', sans-serif;
    margin: 0;
    padding: 20px;
}

.gradient-background h1 {
    font-size: 4rem;
    font-weight: 600;
    line-height: 1;
}

.gradient-background p {
    font-size: 1.5rem;
    font-weight: 300;
}

.gradient-background a {
    border: 2px solid white;
    padding: 10px 20px;
    color: white;
    text-decoration: none;
    font-weight: 500;
    border-radius: 5px;
    transition: all 0.3s;
}

.gradient-background a:hover {
    background: rgba(255, 255, 255, 1);
    color: #0b7074;
}